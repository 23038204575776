import './src/scss/style.scss'

export const onClientEntry = () => {
    if (process.env.GATSBY_MAINTENANCE_MODE === 'true' && typeof window !== 'undefined') {
        if (!window.location.pathname.startsWith('/maintenance') && !window.location.pathname.startsWith('/admin')) {
            window.location = '/maintenance/'
        }
    }
}

export const onRouteUpdate = () => {
    if (process.env.GATSBY_MAINTENANCE_MODE === 'true' && typeof window !== 'undefined') {
        if (!window.location.pathname.startsWith('/maintenance') && !window.location.pathname.startsWith('/admin')) {
            window.location = '/maintenance/'
        }
    }
}
